import React, {useState} from "react"
import {graphql, Link} from "gatsby"
import DefaultHead from "../components/layout/head";

const labels = {
    fr:{
        "title":"Commande",
        "project_type":"Typologie",
        "manufacturer":"Éditeur textile",
        "client":"Client",
        "location":"Ville",
    },
    en:{
        "title":"Product",
        "project_type":"Type",
        "manufacturer":"Textile editor",
        "client":"Customer",
        "location":"City",
    },
}

const PageProjects = ({data,pageContext})=>{
    const projects = data.projects.nodes;
    const {lang, pageViewCount=0, isDesktop=false, isIntro=null} = pageContext;
    const firstLoadDelay = (pageViewCount > 1 && !isIntro) ? 0 : (isDesktop ? 6250 : 2250);
    const firstSpeedDelay = (pageViewCount > 1 && !isIntro) ? 100 : 150;
    const projectSlug = lang==='fr' ? 'projet':'project';
    const columns = [
        {"id":"title"},
        {"id":"project_type"},
        {"id":"manufacturer"},
        {"id":"client"},
        {"id":"location"},
    ]

    const [visitedProjects,setVisitedProject] = useState([]);

    const [hoverId,setHoverId] = useState(false)

    function onEnter(e){
        setHoverId(e.currentTarget.dataset.id);
    }

    function onLeave(e){
        setHoverId(false);
    }

    function onVisitProject(key){
        setVisitedProject(visited=>{
            visited.push(key);
            return visited
        })
    }

    return (
        <div className={"projects-table"} role={"table"}>
            <div className={"row row--head"} role={"rowheader"} style={{animationDelay:`${firstLoadDelay}ms`}}>
                {columns.map((column,key)=>(
                    <span key={key} role={"cell"}
                          className={`column column-${column.id} column--head column--${column.id===hoverId?'over':'out'}`}
                    >{labels[lang][column.id]}{' '}</span>
                ))}
            </div>
            {projects.map((project,key)=>{
                const {frontmatter} = project
                const {link_rewrite} = frontmatter
                const visited = visitedProjects.includes(key);
                return (
                    <Link onClick={e=>onVisitProject(key)} to={`/${lang}/${projectSlug}/${link_rewrite}`} key={key} className={`row ${visited && 'row--visited'}`} role={"row"} style={{animationDelay:`${firstLoadDelay+key*firstSpeedDelay}ms`}}>
                        {columns.map((column,k)=>(
                            <span tabIndex={k+key*columns.length} key={k} data-id={column.id} className={`column column-${column.id}`} onMouseEnter={onEnter} onMouseLeave={onLeave} role={"button"}>{frontmatter[column.id]}{' '}</span>
                        ))}
                    </Link>
                )
            })}
        </div>
    )
}

export default PageProjects

export const query = graphql`
    query ($absPathRegex: String) {
        projects:allMarkdownRemark(
            filter: {fileAbsolutePath: {regex: $absPathRegex}}
            sort: {fields: frontmatter___position, order: ASC}
        ) {
            nodes {
                frontmatter{
                    title
                    id_project
                    link_rewrite
                    location
                    manufacturer
                    project_type
                    client
                }
            }
        }
    }
`

export const Head = DefaultHead